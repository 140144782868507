<template>
  <div class="pucontainer">
    <div class="back">
      <span class="iconfont icon-jtback" @click="$router.go(-1)">{{
        $t('common.back')
      }}</span>
    </div>
    <q-form ref="myForm" @submit="onSubmit">
      <div class="infoCon">
        <h5>
          {{ isBind ? $t('account.bind_phone') : $t('account.change_phone') }}
        </h5>
        <div class="inputdiv" v-if="change">
          <q-input
            maxlength="11"
            type="tel"
            v-model="form.phone"
            autofocus
            :label="$t('login_mod.old_phone_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
          <p v-if="$route.query.email != ''">
            {{ $t('login_mod.ro_t')
            }}<span class="changet blod" @click="change = false">{{
              $t('login_mod.email_t')
            }}</span>
          </p>
        </div>
        <div class="inputdiv" v-else>
          <q-input
            autofocus
            maxlength="30"
            type="email"
            v-model="form.email"
            :label="$t('account.email')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_email'),
              (val) => emailRule(val) || $t('login_mod.email_format')
            ]"
          />
          <p v-if="!isBind">
            {{ $t('login_mod.ro_t')
            }}<span class="changet blod" @click="change = true">{{
              $t('login_mod.phone_t')
            }}</span>
          </p>
        </div>
        <div class="inputdiv">
          <q-input
            type="text"
            maxlength="6"
            v-model="form.code"
            :label="$t('login_mod.verification_code')"
            :rules="[
              (val) =>
                (val !== null && val !== '') ||
                $t('login_mod.send_verification_code')
            ]"
          >
            <template v-slot:append>
              <q-btn
                :loading="SendCodeLoading"
                :disable="SendCodeDisabled"
                color="primary"
                :label="$t('login_mod.send_verification_code') + time"
                @click="sendCode()"
              /> </template
          ></q-input>
          <!-- <p>有效时间为60秒</p> -->
        </div>
        <div class="inputdiv">
          <q-input
            maxlength="11"
            type="tel"
            v-model="form.phone2"
            :label="$t('login_mod.new_phone_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            type="text"
            maxlength="6"
            v-model="form.code2"
            :label="$t('login_mod.verification_code')"
            :rules="[
              (val) =>
                (val !== null && val !== '') ||
                $t('login_mod.enter_verification_code')
            ]"
          >
            <template v-slot:append>
              <q-btn
                :loading="SendCodeLoading2"
                :disable="SendCodeDisabled2"
                color="primary"
                :label="$t('login_mod.send_verification_code') + time2"
                @click="sendCode2()"
              /> </template
          ></q-input>
        </div>
        <div class="infoBtn">
          <q-btn
            type="submit"
            class="full-width"
            color="primary"
            :label="$t('login_mod.submit')"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { pwdRule, emailRule, phoneRule } from '@/common/formRules'
import { changePhoneApi, emailBindPhoneApi } from '@/api/login'
export default {
  data () {
    return {
      change: true, // true 为手机登录，false为邮箱登录
      form: {
        phone: '',
        email: '',
        phone2: '',
        code: '',
        code2: ''
      },
      time: '',
      SendCodeLoading: false,
      SendCodeDisabled: false,
      time2: '',
      SendCodeLoading2: false,
      SendCodeDisabled2: false,
      isBind: false,
      noEmail: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.change) {
        const res = await changePhoneApi({
          mark: this.form.phone,
          code: this.form.code,
          newmark: this.form.phone2,
          newcode: this.form.code2
        })
        if (res.status === 3) {
          this.$router.go(-1)
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        const res = await emailBindPhoneApi({
          mark: this.form.email,
          code: this.form.code,
          newmark: this.form.phone2,
          newcode: this.form.code2
        })
        if (res.status === 3) {
          this.$router.go(-1)
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
    },
    // 发送验证码
    async sendCode () {
      if (this.SendCodeLoading || this.SendCodeDisabled) {
        return
      }
      if (this.change) {
        if (this.form.phone === '' || !phoneRule(this.form.phone)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          return
        }
        this.SendCodeLoading = true
        changePhoneApi({
          mark: this.form.phone
        }).then(res => {
          if (res.status === 1) {
            this.timerStart()
          } else if (res.status === -4) {
            this.$q.notify(this.$t('login_mod.old_phone_t_err'))
          } else {
            this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
          }
          this.SendCodeLoading = false
        })
      } else {
        if (this.form.email === '' || !emailRule(this.form.email)) {
          this.$q.notify(this.$t('login_mod.email_format'))
          return
        }
        this.SendCodeLoading = true
        emailBindPhoneApi({
          mark: this.form.email
        }).then(res => {
          if (res.status === 1) {
            this.timerStart()
          } else if (res.status === -4) {
            this.$q.notify(this.$t('login_mod.old_email_t_err'))
          } else {
            this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
          }
          this.SendCodeLoading = false
        })
      }
    },
    // 发送验证码2
    async sendCode2 () {
      if (this.SendCodeLoading2) {
        return
      }
      if (this.change) {
        if (this.form.phone === '' || !phoneRule(this.form.phone)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          return
        }
        if (this.form.code === '') {
          this.$q.notify(this.$t('login_mod.enter_verification_code'))
          return
        }
        if (this.form.phone2 === '' || !phoneRule(this.form.phone2)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          return
        }
        this.SendCodeLoading2 = true
        const res = await changePhoneApi({
          mark: this.form.phone,
          code: this.form.code,
          newmark: this.form.phone2
        })
        if (res.status === 1) {
          this.timerStart2()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
        this.SendCodeLoading2 = false
      } else {
        if (this.form.email === '' || !emailRule(this.form.email)) {
          this.$q.notify(this.$t('login_mod.enter_email'))
          return
        }
        if (this.form.code === '') {
          this.$q.notify(this.$t('login_mod.enter_verification_code'))
          return
        }
        if (this.form.phone2 === '' || !phoneRule(this.form.phone2)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          return
        }
        this.SendCodeLoading2 = true
        const res = await emailBindPhoneApi({
          mark: this.form.email,
          code: this.form.code,
          newmark: this.form.phone2
        })
        if (res.status === 1) {
          this.timerStart2()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
        this.SendCodeLoading2 = false
      }
    },
    // 倒计时开始
    timerStart () {
      if (this.SendCodeDisabled) {
        return
      }
      this.SendCodeDisabled = true
      let timer = null
      this.time = 60
      timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearTimeout(timer)
          this.time = ''
          this.SendCodeDisabled = false
        }
      }, 1000)
    },
    // 倒计时开始
    timerStart2 () {
      if (this.SendCodeDisabled2) {
        return
      }
      this.SendCodeDisabled2 = true
      let timer = null
      this.time2 = 60
      timer = setInterval(() => {
        this.time2--
        if (this.time2 === 0) {
          clearTimeout(timer)
          this.time2 = ''
          this.SendCodeDisabled2 = false
        }
      }, 1000)
    }
  },
  mounted () {
    if (this.$route.query.phone === '') {
      this.isBind = true
      this.change = false
    }
  },
  setup () {
    return {
      pwdRule,
      emailRule,
      phoneRule
    }
  }
}
</script>

<style lang="scss" scoped>
@import './puinput.scss';
</style>
